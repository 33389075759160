import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LogoIcon from '../Root/LogoIcon';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://vgit.thuanphuoc.viettelcloud.vn',
    label: 'vGit',
    icon: <LogoIcon />
  },
  {
    url: 'https://dex.thuanphuoc.viettelcloud.vn',
    label: 'DevSpaces',
    icon: <LogoIcon />,
  },
  {
    url: 'https://console.viettelcloud.vn',
    label: 'Cloud Console',
    icon: <LogoIcon />,
  },
];
